<template>
  <div class="recorded-info" v-if="courseInfo">
    <div class="video-box">
      <div class="back" @click="$router.back(-1)"></div>
      <div id="paler-box" v-show="!mask"></div>
      <img class="course-img" :src="courseInfo.detail.image" v-show="mask" />
      <div
        class="mask"
        v-if="noLogin_play"
        @click="playVideo1"
        v-show="mask"
      ></div>
      <div class="mask" v-else @click="playVideo" v-show="mask"></div>
      <!-- <div class="progress" v-if="websiteData.is_course_drag==0"><p class="lines" :style="{width:section.progress+'%'}"></p></div> -->
      <div class="marquee" v-if="marqueeState">
        <marquee scrollamount="5">{{marqueeText}}</marquee>
      </div>
    </div>
    <div class="course-info">
      <p class="price" v-if="websiteData.is_course_pay == 1">
        <span class="unit" v-if="courseInfo.detail.price != '0.00'">￥</span
        >{{
          courseInfo.detail.price == "0.00" ? "免费" : courseInfo.detail.price
        }}
        <span
          class="original-cost"
          v-if="courseInfo.detail.original_price != '0.00'"
          >￥{{ courseInfo.detail.original_price }}</span
        >
      </p>
      <p class="course-name vertical-ellipsis">{{ courseInfo.detail.name }}</p>
      <p class="subtitle">
        <span
          >主讲人：<span
            v-for="teacher in courseInfo.teachers"
            :key="teacher.id"
            @click="goHomePage(teacher)"
            >{{ teacher.name?teacher.name:'特约讲师' }}</span
          ><span class="dot">·</span>{{ courseInfo.chapter_count }}章<span
            class="dot"
            >·</span
          >{{ courseInfo.duration }}课时
          <span class="intro" @click="showModalClick(1)">简介</span></span
        >
      </p>
      <div class="marks" v-if="courseInfo.course_tags.length > 0">
        <div class="mark" v-for="tag in courseInfo.course_tags" :key="tag">
          {{ tag }}
        </div>
      </div>
    </div>
    <div class="tab-box">
      <div class="tab-titles">
        <div
          class="tab-title"
          :class="tabIndex == 2 ? 'active' : ''"
          @click="tabOpe(2)"
        >
          目录
        </div>
        <!-- <div class="tab-title" :class="tabIndex==1?'active':''" @click="tabOpe(1)">讲师</div> -->
        <div
          class="tab-title"
          :class="tabIndex == 3 ? 'active' : ''"
          @click="tabOpe(3)"
        >
          评价（{{
            !evaluation || evaluation.data.length == 0
              ? 0
              : evaluation.data.length
          }}）
        </div>
        <div
          class="tab-title"
          :class="tabIndex == 4 ? 'active' : ''"
          @click="tabOpe(4)"
        >
          课程介绍
        </div>
      </div>
      <!-- <div class="tab-content" v-show="tabIndex==1">
        <div v-html="courseInfo.detail.desc"></div>
      </div> -->
      <div class="tab-content" v-show="tabIndex == 2">
        <ul
          class="directory"
          v-for="(item, index) in chapterSection"
          :key="item.id"
        >
          <li class="chapter">
            <div class="chapter-name" @click="onList(index)">
              {{ item.name }}
              <div class="unfold">{{ item.on ? "-" : "+" }}</div>
            </div>
            <transition name="slide-fade-section">
              <ul class="section" v-show="item.on">
                <li
                  class="section-info"
                  :class="selectId == section.id ? 'active' : ''"
                  v-for="section in item.sections"
                  :key="section.id"
                  @click="getPlayInfo(section)"
                >
                  {{ section.name
                  }}<span
                    class="the-length"
                    v-if="courseInfo.detail.is_can_play == 0"
                    >{{ section.duration }}</span
                  ><span class="progress" v-else>{{ section.progress }}%</span>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
      </div>
      <ul class="evaluate-list" v-show="tabIndex == 3">
        <li class="evaluation-btn" v-if="websiteData.is_comment == 1">
          <button class="btn" @click="showModalClick(2)">我要评价</button>
        </li>
        <li
          class="evaluate-info"
          v-for="item in evaluation.data"
          :key="item.id"
        >
          <img
            class="user-img"
            :src="item.avatar || require('@/assets/head-default-personal.png')"
          />
          <div class="evaluate-content">
            <div class="evaluate-title">
              {{ item.name }}<span class="date">{{ item.created_at }}</span>
            </div>
            <div class="grade">
              <Star :type="'show'" :scoreNum="item.score" />
              <span class="grade-statistic">{{ item.score }}分</span>
            </div>
            <div class="evaluate-text">{{ item.content }}</div>
          </div>
        </li>
        <li
          class="evaluate-info nodata"
          v-if="!evaluation || evaluation.data.length == 0"
        >
          <img class="nodata-img" :src="require('@/assets/no-data.png')" />
          <p class="nodata-text">暂无评价</p>
        </li>
      </ul>
      <div
        v-show="tabIndex == 4"
        class="descText"
        v-html="courseInfo.detail.desc"
      ></div>
    </div>
    <div class="bottom">
      <div
        class="collection"
        :class="isCollect == 1 ? 'active' : ''"
        @click="collectBtn(courseInfo.detail.id)"
      >
        收藏
      </div>
      <!-- <div class="share">分享</div> -->
      <div class="btns" v-if="!userContInfo">
        <button class="btn try" @click="tryBtn">试听课程</button>
        <!-- <button class="btn buy" @click="payNowBtn(courseInfo.detail.id)">
          立即购买
        </button> -->
      </div>
      <div class="btns" v-else>
        <button class="btn try" v-if="noLogin_play" @click="playVideo1">
          立即播放
        </button>
        <button class="btn try" v-else @click="playVideo">立即播放</button>
      </div>
    </div>
    <transition name="slide-fade">
      <div
        class="modal evaluation"
        v-show="showModal"
        @click="showModal = false"
      >
        <div class="modal-content" @click.stop>
          <p class="content-title">
            {{ modalType == 1 ? "课程简介" : "我要评价" }}
          </p>
          <div
            class="close-modal"
            v-if="modalType == 1"
            @click="showModal = false"
          ></div>
          <button v-else class="release-btn" @click="submitBtn">发布</button>
          <div v-if="modalType == 1">{{ courseInfo.detail.intro }}</div>
          <div class="grade" v-if="modalType == 2">
            <span>满意度评价</span>
            <Star :type="'ope'" @returnNum="getScore" />
          </div>
          <textarea
            class="evaluate-text"
            v-model="textarea"
            placeholder="还有什么想说的？"
            v-if="modalType == 2"
          ></textarea>
          <div class="evaluate-ope" v-if="modalType == 2">
            <label class="anonymity"
              ><input type="checkbox" v-model="anonymous" /> 是否匿名</label
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Star from "@/components/pc/star"; //评分
import { mapMutations } from "vuex";
export default {
  name: "recordedInfo",
  data() {
    return {
      anonymous: false, //是否匿名
      textarea: "", //评价内容
      score: "", //评分
      selectId: "",
      mask: true, //视频遮罩
      videoModal: false,
      tabIndex: 2,
      loginTip: false, //登录提示
      courseInfo: "", //课程详情
      chapterSection: "", //课程章节
      evaluation: "", //评价
      isCollect: "", //是否收藏
      player: "", //播放器
      section: "", //当前章节
      showModal: false,
      modalType: "", //弹窗类型
      textarea: "", //评价内容
      autoPlayNextTip: "秒后自动播放下一节", //自动播放下一节提示语
      autoPlayNextTime: 5, //自动播放下一节时间 秒
      websiteData: "",
      linkUrlCont:'',
      hostLink: "",
      isLogin: false,
      noLogin_play: false,
      timesNum: "",
      timesInterval: "",
      unique_id:'',//随机数
      flowTimer:'',//流量计时器
       marqueeState:false,//视频水印是否开始
      marqueeText:'',//水印内容
      userContInfo:'',//登录者信息
    };
  },
  components: {
    Star,
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('userInfo'))){
      this.marqueeText = JSON.parse(localStorage.getItem('userInfo')).name
      this.userContInfo =  JSON.parse(localStorage.getItem('userInfo'))
    }

     // 获取随机数
    this.unique_id=String(new Date().getTime())+String(Math.round(Math.random()*999999));
    const websiteData = JSON.parse(localStorage.getItem('websiteState'));
    this.linkUrlCont = JSON.parse(localStorage.getItem("siteInfo"));

    this.websiteData = websiteData || this.$store.state.websiteData;

    if(websiteData){
      this.timesNum = this.websiteData.lesson_prompt_time_limit * 1000 * 60;
    }else{
      this.getWebsiteData();
    }
    this.hostLink =
      window.location.host == "localhost:8081"
        ? "kskweb.kskstudy.com"
        : window.location.host;
    this.isLogin = JSON.parse(localStorage.getItem("userInfo"))
      ? true
      : false;

    this.getCourseInfo(); //获取详情
  },
  methods: {
    ...mapMutations([
      "ShowMLoginModal",
      "CloseMLoginModal",
      "ShowTipModal",
      "CloseTipModal",
    ]), //登录弹窗
    async getWebsiteData(){
      await this.$axios.post(`/v1/home/getStatus`, { host: window.location.host }).then((res) => {
        if (res.code == 0) {
          this.websiteData = res.data;
          this.timesNum = res.data.lesson_prompt_time_limit * 1000 * 60;
        }
      });
    },
    //获取子组件分数
    getScore(data) {
      this.score = data;
    },
    //展开目录
    onList(index) {
      this.chapterSection[index].on = !this.chapterSection[index].on;
    },
    //弹窗
    showModalClick(type) {
      this.modalType = type;

      if (type == 2) {
        //评价
        if (localStorage.getItem("userInfo")) {
          this.textarea = "";
          this.showModal = true;
        } else {
          this.ShowMLoginModal();
        }
      } else {
        this.showModal = true;
      }
    },
    //提交评价
    submitBtn() {
      if (this.score == 0) {
        this.ShowTipModal({
          text: "请先打分", //提示弹窗内容
        });

        return;
      }

      if (this.textarea == "") {
        this.ShowTipModal({
          text: "请填写评价内容", //提示弹窗内容
        });

        return;
      }

      this.$axios
        .post(
          `/v1/course/play/evaluation`,
          {
            course_id: this.courseInfo.detail.id,
            is_anonymous: this.anonymous ? 1 : 0,
            score: this.score,
            content: this.textarea,
            host: this.hostLink,
          },
          { useLog: true }
        )
        .then((res) => {
          this.$axios
            .post(`/v1/px/course/courseRecord`, {
              course_id: this.courseInfo.detail.id,
              type: 2,
            })
            .then(() => {}); //记录评价
          if (res.code == 0) {
            let _this = this;
            this.ShowTipModal({
              text: "评论成功", //提示弹窗内容
            });

            setTimeout(function () {
              _this.showModal = false;
              _this.getEvaluation();
            }, 2000);
          } else {
            this.ShowTipModal({
              text: res.msg, //提示弹窗内容
            });
          }
        });
    },
    //获取详情
    getCourseInfo() {
      let data = {
        id: this.$route.params.id,
        host: this.hostLink,
      };

      if (localStorage.getItem("user")) {
        data.user_id = JSON.parse(localStorage.getItem("user")).id;
      }

      this.$axios.post(`/v1/course/detail`, data).then((res) => {
        if (res.code == 0) {
          this.courseInfo = res.data;
          this.isCollect = res.data.is_collect;

          this.getEvaluation(); //获取评价
          if (
            !this.isLogin &&
            this.websiteData.is_no_login_look == 1 &&
            this.courseInfo.detail.is_can_play == 1
          ) {
            // 未登录 可播放
            this.noLogin_play = true;
          }
          this.tabOpe(2);
        } else {
          this.ShowTipModal({
            text: res.msg, //提示弹窗内容
            showBtns: true,
            confirmFun: function () {
              _this.$router.go(-1);
            },
          });
        }
      });
    },
    //tab切换
    tabOpe(index) {
      this.tabIndex = index;
      let url = this.userContInfo
        ? "/v1/course/play/chapterSection"
        : "/v1/course/noLoginChapterSection";
      let url1 = this.userContInfo
        ? "/v1/course/detail/chapterSection"
        : "/v1/course/noLoginChapterSection" ;
      // let url = this.noLogin_play
      //   ? "/v1/course/noLoginChapterSection"
      //   : "/v1/course/play/chapterSection";
      // let url1 = this.noLogin_play
      //   ? "/v1/course/noLoginChapterSection"
      //   : "/v1/course/detail/chapterSection";

      //获取章节
      if (index == 2 && this.chapterSection == "") {
        if (this.courseInfo.detail.is_can_play != 0) {
          this.$axios
            .post(
              url,
              { course_id: this.$route.params.id, host: this.hostLink },
              { useLog: true }
            )
            .then((res) => {
              if (res.code == 0) {
                let chapterSection = res.data.chapters;

                for (let i = 0; i < chapterSection.length; i++) {
                  chapterSection[i].on = false;
                }

                this.chapterSection = chapterSection;
              }
            });
        } else {
          //获取课程章节列表
          this.$axios
            .post(url1, {
              id: this.$route.params.id,
            })
            .then((res) => {
              let chapterSection = res.data;

              for (let i = 0; i < chapterSection.length; i++) {
                chapterSection[i].on = false;
              }

              this.chapterSection = chapterSection;
            });
        }
      }
    },
    //获取评价
    getEvaluation() {
      //获取评论
      this.$axios
        .post(`/v1/course/detail/evaluation`, {
          id: this.$route.params.id,
          page: 1,
          pre_page: 10,
          host: this.hostLink,
        })
        .then((res) => {
          this.evaluation = res.data;
        });
    },
    //立即购买
    payNowBtn(id) {
      //判断登录
      let userInfo = localStorage.getItem("user");

      if (userInfo) {
        this.$router.push({ path: "/confirmOrder/" + id });
      } else {
        this.ShowMLoginModal();
      }
    },
    //收藏
    collectBtn(id) {
      let userInfo = localStorage.getItem("user");

      if (userInfo) {
        this.$axios
          .post(`/v1/course/detail/collect`, { id }, { useLog: true })
          .then((res) => {
            if (res) {
              this.isCollect = this.isCollect == 1 ? 0 : 1;
              if (this.isCollect == 1) {
                this.$axios
                  .post(`/v1/px/course/courseRecord`, {
                    course_id: this.courseInfo.detail.id,
                    type: 3,
                  })
                  .then(() => {}); //记录收藏
              }
            }
          });
      } else {
        this.ShowMLoginModal();
      }
    },
    //试看
    tryView() {
      this.$axios
        .post(
          `/v1/course/detail/testVideo`,
          {
            id: this.$route.params.id,
          },
          { useLog: true }
        )
        .then((res) => {
          let _this = this;
          this.videoModal = true;
          this.player = new Aliplayer(
            {
              id: "paler-box",
              width: "100%",
              height: "100%",
              source: res.data.info ? res.data.info.original_link : "", //视频地址
              autoplay: false,
              showBarTime: 1000,

              skinLayout: [
                { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                { name: "H5Loading", align: "cc" },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    { name: "progress", align: "tlabs", x: 0, y: 0 }, //隐藏进度条
                    { name: "playButton", align: "tl", x: 15, y: 13 },
                    { name: "timeDisplay", align: "tl", x: 10, y: 6 },
                    { name: "fullScreenButton", align: "tr", x: 20, y: 12.5 },
                    { name: "volume", align: "tr", x: 20, y: 12.5 },
                  ],
                },
              ],
            },
            function (player) {
              if (_this.websiteData.is_course_drag == 0) {
                player.seek = (time) => {};
              }
              player.setPreviewTime(3 * 60); //试看3分钟

              //暂停播放
              player.on("pause", function () {
                if (player.getCurrentTime() >= 180) {
                  _this.ShowTipModal({
                    text: "试听已结束，查看更多请登录", //提示弹窗内容
                    showBtns: true,
                    subBtnText: "购买",
                    confirmFun: function () {
                      _this.CloseTipModal();
                      _this.ShowMLoginModal();
                      // _this.payNowBtn(_this.courseInfo.detail.id);
                    },
                  });
                }
              });
            }
          );
          this.player.play();
        });
    },
    //关闭视频弹窗
    closeVideoModal() {
      if (this.player) {
        this.videoModal = false;
        this.player.dispose();
      }
    },
    //点击视频遮罩
    playVideo() {
      this.mask = false;

      if (this.userContInfo) {
        this.getPlayInfo(this.chapterSection[0].sections[0]);
      } else {
        this.tryView();
      }
      // if (localStorage.getItem("user")) {
        
      // } else {
      //   this.ShowMLoginModal();
      // }
    },
    playVideo1() {
      this.getPlayInfo(this.chapterSection[0].sections[0]);
    },
    //试听按钮
    tryBtn() {
      if (this.player) {
        if (this.player.getStatus() == "pause") {
          this.player.play();
        }
      } else {
        this.playVideo();
      }
    },
    //章节播放
    getPlayInfo(section) {
      if (this.courseInfo.detail.is_can_play == 0) {
        let _this = this;
        this.ShowTipModal({
          text: "想要查看更多，请购买此课程", //提示弹窗内容
          showBtns: true,
          subBtnText: "购买",
          confirmFun: function () {
            _this.CloseTipModal();
            _this.payNowBtn(_this.courseInfo.detail.id);
          },
        });
      } else {
        let _this = this;
        this.selectId = section.id;
        this.section = section;
        let url = this.noLogin_play
          ? "/v1/course/noLoginResource"
          : "/v1/course/play/resource";
        this.$axios
          .post(
            url,
            {
              course_id: this.courseInfo.detail.id,
              section_id: section.id,
              host: this.hostLink,
            },
            { useLog: true }
          )
          .then((res) => {
            let playInfo = res.data;

            if (this.player) {
              this.player.dispose();
            }

            this.mask = false;
            this.player = new Aliplayer(
              {
                id: "paler-box",
                width: "100%",
                height: "100%",
                source: playInfo.info.original_link, //视频地址

                // 播放方式二：推荐点播用户使用
                // vid : playInfo.info.video_params.PlayAuth,//播放方式二必选参数。可以通过点播控制台（媒资库>音/视频路径）查询。示例：1e067a2831b641db90d570b6480f****。
                // playauth : playInfo.info.video_params.VideoMeta.VideoId,//播放方式二必选参数。参数值可通过调用GetVideoPlayAuth接口获取。
                // encryptType: 1, //使用播放方式二，当播放私有加密流时需要设置本参数值为1。其它情况无需设置。

                autoplay: false,
                showBarTime: 1000,
                skinLayout: [
                  { name: "bigPlayButton", align: "blabs", x: 30, y: 80 },
                  { name: "H5Loading", align: "cc" },
                  {
                    name: "controlBar",
                    align: "blabs",
                    x: 0,
                    y: 0,
                    children: [
                      { name: "progress", align: "tlabs", x: 0, y: 0 }, //隐藏进度条
                      { name: "playButton", align: "tl", x: 15, y: 13 },
                      { name: "timeDisplay", align: "tl", x: 10, y: 6 },
                      { name: "fullScreenButton", align: "tr", x: 20, y: 12.5 },
                      { name: "volume", align: "tr", x: 20, y: 12.5 },
                    ],
                  },
                ],
              },
              function (player) {
                player.on('ready', () => {
                  //自动播放
                  _this.player.play();
                })

                //开始播放以后监听
                player.on("play", () => {
                  if(_this.linkUrlCont.is_open_video_watermark==1){
                      // 开启水印
                     _this.marqueeState = true
                    }
                    _this.getVideoFlow(); //记录流量
                  if (player.getStatus() != "pause") {
                    _this.$axios
                      .post(`/v1/px/course/courseRecord`, {
                        course_id: _this.courseInfo.detail.id,
                        type: 1,
                      })
                      .then(() => {}); //记录播放
                     
                    //获取进度
                    let jindu = section.progress;

                    if (jindu > 0 && jindu < 100) {
                      setTimeout(() => {
                        //设置计时延迟以兼容ios
                        player.seek(res.data.finish_duration); //跳转到某个已加载的时刻进行播放，时间单位：秒。
                        //禁止推拽进度条
                        if (
                          _this.websiteData.is_course_drag == 0 ||
                          _this.websiteData.is_course_drag == null
                        ) {
                          player.seek = (time) => {};
                        }
                      }, 500);
                    }
                  }
                  if (_this.timesNum > 0) {
                    _this.timesInterval = setInterval(() => {
                      // _this.timesNum = _this.timesNum - 1000;
                      // if (_this.timesNum == 0) {
                      _this.timesNum =
                        this.websiteData.lesson_prompt_time_limit * 1000 * 60;
                      _this.player.pause();
                      clearInterval(_this.timesInterval);
                      clearInterval(_this.timer);
                      _this.ShowTipModal({
                        text: "是否继续观看？", //提示弹窗内容
                        showBtns: true,
                        subBtnText: "继续",
                        confirmFun: function () {
                          _this.CloseTipModal();
                          _this.player.play();
                        },
                      });
                      // }
                    }, _this.websiteData.lesson_prompt_time_limit * 1000 * 60);
                  }

                  if (!_this.noLogin_play) {
                    _this.recordLength(); //记录时长
                  }
                });

                //暂停播放
                player.on("pause", function () {
                  if (_this.timer) {
                    clearInterval(_this.timer);
                  }
                    clearInterval(_this.flowTimer);
                });

                //播放结束
                player.on("ended", function () {
                  if (_this.timer) {
                    clearInterval(_this.timer);
                  }
                  clearInterval(_this.flowTimer);
                  if (!_this.noLogin_play) {
                    _this.recordLength(); //记录时长
                  }
                  
                  _this.playNext(); //自动播放下一节
                });
              }
            );
          });
      }
    },
     // 记录流量
    getVideoFlow(){
      let _this = this
      this.flowTimer = setInterval(()=>{
        this.$axios.post(`/v1/course/statistics/statisticsVideoFlow`,{
          section_id: _this.section.id,
          unique_id:_this.unique_id
        }).then(res=>{
          if(res.code==0){
            // for (let i = 0; i < _this.chapters.chapters.length; i++) {
            //     if (_this.chapters.chapters[i].id == _this.section.chapter_id) {
            //       for (
            //         let o = 0;
            //         o < _this.chapters.chapters[i].sections.length;
            //         o++
            //       ) {
            //         if (
            //           _this.chapters.chapters[i].sections[o].id ==
            //           _this.section.id
            //         ) {
            //           _this.chapters.chapters[i].sections[o].progress =
            //             res.data.progress;
            //         }
            //       }
            //     }
            //   }
          }
        })
      },10000)
    },
    //记录时长
    recordLength() {
      let _this = this;
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        // alert(_this.player.getCurrentTime())
        _this.$axios
          .post(
            `/v1/course/play/recordStudyDuration`,
            {
              course_id: _this.courseInfo.detail.id,
              section_id: _this.section.id,
              play_duration: parseInt(_this.player.getCurrentTime()),
              total_duration: parseInt(_this.player.getDuration()),
              host: this.hostLink,
            },
            { useLog: true }
          )
          .then((res) => {
            if (res.code == 0) {
              for (let i = 0; i < _this.chapterSection.length; i++) {
                if (_this.chapterSection[i].id == _this.section.chapter_id) {
                  for (
                    let o = 0;
                    o < _this.chapterSection[i].sections.length;
                    o++
                  ) {
                    if (
                      _this.chapterSection[i].sections[o].id == _this.section.id
                    ) {
                      _this.chapterSection[i].sections[o].progress =
                        res.data.progress;
                    }
                  }
                }
              }
            }
          });
      }, 10000);
    },
    //播放下一节逻辑
    playNext() {
      let chapterList = this.chapterSection;
      let section = this.section;
      let playSection = null;
      let autoPlayNextTime = this.autoPlayNextTime; //倒计时时间
      let autoPlayTime = null; //倒计时

      for (let i = 0; i < chapterList.length; i++) {
        if (chapterList[i].id == section.chapter_id) {
          let sections = chapterList[i].sections;

          for (let o = 0; 0 < sections.length; o++) {
            if (sections[o].id == section.id) {
              if (o == sections.length - 1 && i == chapterList.length - 1) {
                console.log("本课程已播放完");
              } else {
                let _this = this;

                this.ShowTipModal({
                  text: _this.autoPlayNextTime + _this.autoPlayNextTip, //提示弹窗内容
                  showBtns: true,
                  confirmBtnText: "直接播放下一节",
                  cancelBtnText: "重新播放本节",
                  confirmFun: function () {
                    if (o < sections.length - 1) {
                      _this.selectId = sections[o + 1].id;
                      playSection = sections[o + 1];
                    } else {
                      if (i < chapterList.length - 1) {
                        _this.selectId = chapterList[i + 1].sections[0].id;
                        playSection = chapterList[i + 1].sections[0];
                      }
                    }

                    clearInterval(autoPlayTime);
                    _this.CloseTipModal();
                    _this.getPlayInfo(playSection, true);
                  },
                  cancelFun: function () {
                    clearInterval(autoPlayTime);
                    _this.CloseTipModal();
                    _this.player.play();
                  },
                });

                //自动播放倒计时
                autoPlayTime = setInterval(() => {
                  autoPlayNextTime--;
                  _this.$store.state.tipModal.text =
                    autoPlayNextTime + _this.autoPlayNextTip;

                  if (autoPlayNextTime == 0) {
                    clearInterval(autoPlayTime);
                    _this.$store.state.tipModal.confirmFun();
                    _this.CloseTipModal();
                  }
                }, 1000);
              }
            }
          }
        }
      }
    },
    // 跳转到讲师详情
    goHomePage(item) {
      if (item.id) {
        this.$router.push("/lecturerHomepage/" + item.id);
      }
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.player) {
      this.player = null;
    }
  },
};
</script>
<style scoped lang="scss">
.prism-progress {
  pointer-events: none;
}

.recorded-info {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding-bottom: 98px;
}
.video-box {
  height: 384px;
  position: relative;
  .progress {
    position: absolute;
    position: absolute;
    left: 12px;
    bottom: 6px;
    width: 80%;
    height: 4px;
    background: #eeeeee;
    border-radius: 16px;
    p {
      position: absolute;
      left: 0px;
      top: 0;
      height: 100%;
      background: rgb(0, 193, 222);
      width: 100%;
      border-radius: 16px;
    }
  }
  .back {
    width: 88px;
    height: 88px;
    background: url(~@/assets/arrows.png) center center no-repeat;
    background-size: 44px;
    transform: rotate(90deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .course-img {
    width: 100%;
    height: 100%;
  }
  .mask {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &::after {
      content: "";
      display: block;
      width: 54px;
      height: 54px;
      background: url(~@/assets/play2-ico.png) right center no-repeat;
      background-size: 100% auto;
    }
  }
  .marquee{
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        z-index: 8888;
        display: flex;
        opacity: .5;
      }
}
.course-info {
  padding: 28px;
  background-color: #fff;
  .price {
    font-size: 36px;
    color: #cd1b14;
    font-weight: bold;
    .unit {
      font-size: 22px;
      font-weight: normal;
    }
    .original-cost {
      font-size: 22px;
      color: #ccc;
      font-weight: normal;
      margin-left: 14px;
      text-decoration: line-through;
    }
  }
  .course-name {
    padding-top: 28px;
    font-size: 32px;
    max-height: 88px;
    line-height: 44px;
    margin-bottom: 24px;
  }
  .subtitle {
    font-size: 26px;
    color: #999;
    .dot {
      margin: 0 16px;
    }
    .intro {
      display: inline-block;
      padding-right: 30px;
      margin-left: 28px;
      position: relative;
      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(~@/assets/arrows-right.png) right center no-repeat;
        background-size: 100%;
        transform: rotate(-90deg);
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -10px;
      }
    }
  }
}
.marks {
  margin-top: 24px;
  .mark {
    height: 38px;
    line-height: 38px;
    padding: 0 10px;
    font-size: 24px;
  }
}
.tab-box {
  margin-top: 18px;
  background-color: #fff;
  .tab-titles {
    padding: 0 60px;
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    .tab-title {
      padding: 23px 0;
      font-size: 28px;
      color: #999;
      margin-right: 60px;
      &.active {
        color: #333;
        position: relative;
        &::after {
          content: "";
          width: 100%;
          height: 4px;
          background-color: #254ed4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
  }
}
.tab-content {
  padding: 30px 26px;
}
.descText {
  padding: 30px 26px;
  font-size: 14px;
}
.chapter {
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 44px;
  &:last-child {
    border: none;
    margin-bottom: 0;
  }
  .chapter-name {
    height: 28px;
    line-height: 28px;
    padding: 0 26px 0 52px;
    background: url(~@/assets/list-ico.png) left center no-repeat;
    background-size: auto 100%;
    font-size: 26px;
    color: #333;
    margin-bottom: 44px;
    position: relative;
    .unfold {
      width: 26px;
      height: 26px;
      font-size: 34px;
      text-align: center;
      position: absolute;
      top: 0px;
      right: 0;
    }
  }
  .section {
    padding-left: 52px;
    overflow: hidden;
    .section-info {
      min-height: 24px;
      line-height: 28px;
      padding-left: 44px;
      font-size: 26px;
      color: #333;
      display: flex;
      justify-content: space-between;
      background: url(~@/assets/play-ico.png) left center no-repeat;
      // background-size: auto 100%;
      background-size: 24px 24px;
      display: flex;
      margin-bottom: 28px;
      &.active {
        color: #254ed4;
      }
      .the-length {
        width: 100px;
        height: 24px;
        line-height: 24px;
        padding-left: 28px;
        background: url(~@/assets/clock-grey-ico.png) left center no-repeat;
        background-size: auto 100%;
        float: right;
        font-size: 20px;
        color: #999;
      }
      .progress {
        // float: right;
        display: block;
        margin-left: 15px;
        min-width: 50px;
      }
    }
  }
}

.bottom {
  width: 100%;
  height: 98px;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  .collection,
  .share {
    min-width: 44px;
    padding-top: 48px;
    background: url(~@/assets/star-air2-ico.png) center top no-repeat;
    background-size: 44px auto;
    font-size: 24px;
    color: #999;
    text-align: center;
    margin-left: 60px;
  }
  .collection.active {
    background: url(~@/assets/star-blue-ico.png) center top no-repeat;
    background-size: 44px auto;
  }
  .share {
    background: url(~@/assets/share.png) center top no-repeat;
    background-size: 44px auto;
    font-size: 24px;
    margin-left: 106px;
  }
  .btns {
    margin-left: auto;
    .btn {
      width: 204px;
      height: 98px;
      line-height: 98px;
      text-align: center;
      font-size: 28px;
      color: #fff;
      float: left;
      &.try {
        background-color: #f5a623;
      }
      &.buy {
        background-color: #254ed4;
      }
    }
  }
}
.evaluation-btn {
  padding: 88px 0;
  .btn {
    display: block;
    width: 136px;
    height: 44px;
    background: #9da5bd;
    color: #fff;
    font-size: 24px;
    border-radius: 6px;
    margin: 0 auto;
  }
}
.evaluate-info {
  padding: 0 0 47px 40px;
  overflow: hidden;
  &.nodata {
    padding-left: 0;
    color: #999;
  }
}
.nodata-img {
  display: block;
  width: 576px;
  height: auto;
  margin: 0 auto;
}
.nodata-text {
  text-align: center;
}
.user-img {
  width: auto;
  height: 88px;
  border-radius: 50%;
  float: left;
}
.evaluate-content {
  width: 570px;
  float: left;
  margin-left: 24px;
}
.evaluate-title {
  font-size: 26px;
  color: #666;
  margin-bottom: 15px;
  .date {
    font-size: 24px;
    color: #999;
    float: right;
  }
}
.grade {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
::v-deep .stars {
  .star {
    width: 24px;
    height: 24px;
    background-size: 100%;
    margin-right: 8px;
  }
}
.grade-statistic {
  font-size: 24px;
  color: #999;
  margin-left: 5px;
}
.evaluate-text {
  font-size: 28px;
  color: #333;
  line-height: 36px;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 98px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 88;
  .modal-content {
    box-sizing: border-box;
    width: 100%;
    height: 600px;
    transition: height 1s;
    padding: 46px 30px;
    background-color: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    .content-title {
      font-size: 32px;
      color: #333;
      margin-bottom: 30px;
    }
    .close-modal {
      width: 100px;
      height: 100px;
      background: url(~@/assets/arrows.png) center center no-repeat;
      background-size: 50px auto;
      position: absolute;
      top: 0;
      right: 0;
    }
    .release-btn {
      width: 80px;
      height: 42px;
      font-size: 26px;
      color: #fff;
      line-height: 42px;
      background: #9da5bd;
      border-radius: 6px;
      position: absolute;
      top: 48px;
      right: 28px;
    }
  }
  ::v-deep .grade {
    display: flex;
    align-items: center;
    .stars {
      margin-left: 25px;
      .star {
        width: 24px;
        height: 24px;
        background-size: 100%;
      }
    }
  }
  .evaluate-text {
    box-sizing: border-box;
    width: 100%;
    height: 234px;
    padding: 20px;
    background: #f5f5f5;
    border-radius: 6px;
    font-size: 28px;
    color: #333;
    &::placeholder {
      color: #999;
    }
  }
}
.evaluate-ope {
  font-size: 24px;
  color: #999;
  margin-top: 24px;
  input {
    width: 24px;
    height: 24px;
  }
}
//弹出动画
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

//slide-fade-section
.slide-fade-section-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-section-leave-active {
  transition: all 0.2s ease;
}
.slide-fade-section-enter, .slide-fade-section-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

::v-deep .prism-player .prism-big-play-btn {
  width: 60px;
  height: 60px;
  // background-size: 128px 256px;
}
::v-deep .prism-player .prism-big-play-btn .outter {
  border: none;
  width: 80px;
  height: 60px;
  border-radius: 100%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
}
</style>